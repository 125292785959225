import biz from '../assets/images/shop/biz.jpg'
import com from '../assets/images/shop/com.jpg'
import me from '../assets/images/shop/me.jpg'
import mobi from '../assets/images/shop/mobi.jpg'
import net from '../assets/images/shop/net.jpg'
import org from '../assets/images/shop/org.jpg'
import tv from '../assets/images/shop/tv.jpg'
import us from '../assets/images/shop/us.jpg'


import blog1 from '../assets/images/blog/1.jpg'
import blog2 from '../assets/images/blog/2.jpg'
import blog3 from '../assets/images/blog/3.jpg'
import blog4 from '../assets/images/blog/4.jpg'
import blog5 from '../assets/images/blog/5.jpg'
import blog6 from '../assets/images/blog/6.jpg'
import blog7 from '../assets/images/blog/7.jpg'
import blog8 from '../assets/images/blog/8.jpg'
import blog9 from '../assets/images/blog/9.jpg'

import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'
import client5 from '../assets/images/client/05.jpg'
import client6 from '../assets/images/client/06.jpg'
import client7 from '../assets/images/client/07.jpg'
import client8 from '../assets/images/client/08.jpg'

import bizImg from '../assets/images/shop/biz.jpg'
import comimg from '../assets/images/shop/com.jpg'
import meImg from '../assets/images/shop/me.jpg'
import mobiImg from '../assets/images/shop/mobi.jpg'
import netImg from '../assets/images/shop/net.jpg'
import orgImg from '../assets/images/shop/org.jpg'
import tvImg from '../assets/images/shop/tv.jpg'
import usImg from '../assets/images/shop/us.jpg'

import ClientLogo1 from '../assets/images/client/logo-1.png'
import ClientLogo2 from '../assets/images/client/logo-2.png'
import ClientLogo3 from '../assets/images/client/logo-3.png'
import ClientLogo4 from '../assets/images/client/logo-4.png'
import ClientLogo5 from '../assets/images/client/logo-5.png'
import ClientLogo6 from '../assets/images/client/logo-6.png'
import ClientLogo7 from '../assets/images/client/logo-7.png'
import ClientLogo8 from '../assets/images/client/logo-8.png'
import ClientLogo9 from '../assets/images/client/logo-9.png'
import ClientLogo10 from '../assets/images/client/logo-10.png'
import ClientLogo11 from '../assets/images/client/logo-11.png'
import ClientLogo12 from '../assets/images/client/logo-12.png'

import {
    VscServer,
    TbCloudHeart,
    MdOutlineMarkEmailRead,
    LuUsers,
    BiDisc,
    LiaFileInvoiceDollarSolid,
    GrDocumentUser,
    BiTachometer,
    LiaSwatchbookSolid,
    FiUserCheck,
    AiOutlineQuestionCircle,
    LuSettings,
    IoIosAlbums,
    SiSecurityscorecard,
    BsDatabaseCheck,
    MdStorage,
    HiOutlineDocumentDuplicate,
    SiWebstorm,
    BsRobot,
    GrDocumentPdf,
    MdOutlineDocumentScanner,
    MdUpdate,
    HiOutlineDocumentMagnifyingGlass,
    GoVersions,
    GrDocumentVerified,
    TbDeviceAnalytics
}
    from '../assets/icons/vander'

export  const domainPlan = [
    {
        id:1,
        image:com,
        name:'.com',
        title:'Com',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:2,
        image:us,
        name:'.in',
        title:'In',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:3,
        image:org,
        name:'.online',
        title:'Online',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:4,
        image:net,
        name:'.net',
        title:'Net',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:5,
        image:com,
        name:'.co',
        title:'Com',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:6,
        image:biz,
        name:'.live',
        title:'Live',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:7,
        image:me,
        name:'.xyz',
        title:'Com',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:8,
        image:org,
        name:'.org',
        title:'Org',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:9,
        image:mobi,
        name:'.club',
        title:'Club',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:10,
        image:me,
        name:'.me',
        title:'Me',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:15,
        image:us,
        name:'.today',
        title:'Today',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:16,
        image:biz,
        name:'.life',
        title:'Life',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:17,
        image:tv,
        name:'.shop',
        title:'Shop',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:18,
        image:mobi,
        name:'.store',
        title:'Store',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:19,
        image:org,
        name:'.solution',
        title:'Solution',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },

]
export const blogData = [
    {
        id:1,
        image:blog1,
        title:'Quickly formulate backend',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        date:'13th Sep 2023'
    },
    {
        id:2,
        image:blog2,
        title:'Progressively visualize enabled',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        date:'29th Nov 2023'
    },
    {
        id:3,
        image:blog3,
        title:'Credibly implement maximizing',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        date:'31st Dec 2023'
    },
    {
        id:4,
        image:blog4,
        title:'Quickly formulate backend',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        date:'13th March 2023'
    },
    {
        id:5,
        image:blog5,
        title:'Progressively visualize enabled',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        date:'6th May 2023'
    },
    {
        id:6,
        image:blog6,
        title:'Credibly implement maximizing',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        date:'19th June 2023'
    },
    {
        id:7,
        image:blog7,
        title:'Quickly formulate backend',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        date:'20th June 2023'
    },
    {
        id:8,
        image:blog8,
        title:'Progressively visualize enabled',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        date:'31st Aug 2023'
    },
    {
        id:9,
        image:blog9,
        title:'Credibly implement maximizing',
        desc:'The phrasal sequence of the is now so that many campaign and benefit',
        date:'1st Sep 2023'
    },

]
 export  const clientData = [
    {
        image:client1,
        name:'Thomas Israel',
        title:'C.E.O',
        desc:"I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!"
    },
    {
        image:client2,
        name:'Barbara McIntosh',
        title:'C.E.O',
        desc:"There are so many things I had to do with my old software that I just don't do at all with Techwind. Suspicious but I can't say I don't love it."
    },
    {
        image:client3,
        name:'Carl Oliver',
        title:'C.E.O',
        desc:"The best part about Techwind is every time I pay my employees, my bank balance doesn't go down like it used to. Looking forward to spending this extra cash when I figure out why my card is being declined."
    },
    {
        image:client4,
        name:'Jill Webb',
        title:'C.E.O',
        desc:"I'm trying to get a hold of someone in support, I'm in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away."
    },
    {
        image:client5,
        name:'Barbara McIntosh',
        title:'C.E.O',
        desc:"I used to have to remit tax to the EU and with Techwind I somehow don't have to do that anymore. Nervous to travel there now though."
    },
    {
        image:client6,
        name:'Janisha Doll',
        title:'C.E.O',
        desc:"This is the fourth email I've sent to your support team. I am literally being held in jail for tax fraud. Please answer your damn emails, this is important."
    },
    {
        image:client7,
        name:'Thomas Israel',
        title:'C.E.O',
        desc:"I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!"
    },
    {
        image:client8,
        name:'Thomas Israel',
        title:'C.E.O',
        desc:"I didn't know a thing about icon design until I read this book. Now I can create any icon I need in no time. Great resource!"
    },
]

export const serviceData = [
    {
        icon:VscServer,
        title:'Web Hosting',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:TbCloudHeart,
        title:'Domains',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:MdOutlineMarkEmailRead,
        title:'Emails',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:LuUsers,
        title:'Supported',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
   
]

export const teamData = [
     {
        image: client7,
        name: 'Nadhir Cheniti',
        title: 'Founder',
        linkedin: "https://www.linkedin.com/in/nadhir-cheniti-a81232bb/"
    },

    {
        image: client6,
        name: 'Ryan Zaiem',
        title: 'Co-Founder',
        linkedin: "https://www.linkedin.com/in/ryan-zaiem-35137a43/"
    },


]
export const counterData = [
    {
        start:'500',
        end:'1548',
        title:'Properties Sell'
    },
    {
        start:'0',
        end:'25',
        title:'Award Gained'
    },
    {
        start:'0',
        end:'9',
        title:'Years Experience'
    },
]
export const domainData = [
    {
        image:bizImg,
        amount:'3.99'
    },
    {
        image:comimg,
        amount:'3.99'
    },
    {
        image:meImg,
        amount:'3.99'
    },
    {
        image:mobiImg,
        amount:'3.99'
    },
    {
        image:netImg,
        amount:'3.99'
    },
    {
        image:orgImg,
        amount:'3.99'
    },
    {
        image:tvImg,
        amount:'3.99'
    },
    {
        image:usImg,
        amount:'3.99'
    },
]

export const serviceData2 = [

    {
        icon: BsRobot,
        title: 'IA Technologie',
        desc: 'Our AI technology empowers you to work smarter, not harder, by automating tasks, enhancing decision-making, and driving innovation across your organization.'
    },
    {
        icon: LuSettings,
        title: 'Compliance',
        desc: 'Ensuring that document management practices comply with legal and regulatory requirements, such as retention policies and data privacy regulations.'
    },
    {
        icon: TbCloudHeart,
        title: 'Cloud',
        desc: 'Our cloud-based Document Management and Capture application leverages the power of cloud computing to deliver a seamless and efficient solution.'
    },
    {
        icon: MdOutlineMarkEmailRead,
        title: 'Export Connector',
        desc: 'Simplify the distribution of digital documents by connecting to popular platforms like Cloud services, SAP, and DMS (Document Management System).'
    },
    {
        icon: LuUsers,
        title: 'Customized Workflow',
        desc: 'Tailor your dematerialization process with a customizable workflow that includes powerful options such as case management, ensuring efficient task management and tracking.'
    },
    {
        icon: HiOutlineDocumentDuplicate,
        title: 'Documents Classification',
        desc: 'Our document classification feature is designed to streamline your document management process by automatically categorizing and organizing your documents. '
    },
    {
        icon: MdStorage,
        title: 'Storage',
        desc: 'Storing documents in a secure and organized manner, which can be physical file cabinets or digital repositories such as document management software or cloud storage.'
    },

    {
        icon: TbDeviceAnalytics,
        title: 'Dashboards',
        desc: 'Our intuitive dashboard provides you with a centralized hub for monitoring and managing your data, activities, and performance metrics in real-time.'
    },

    {
        icon: GrDocumentPdf,
        title: 'PDF Creation',
        desc: 'Our application automatically converts scanned documents into PDF format, ensuring compatibility and ease of access.'
    },
    {
        icon: SiSecurityscorecard,
        title: 'Encryption',
        desc: 'Keep your data secure with advanced encryption measures, protecting sensitive information from unauthorized access.'
    },
    {
        icon: GrDocumentVerified,
        title: 'Retrieval',
        desc: 'Providing easy and quick access to documents when needed, often through search functions or a well-structured filing system.'
    },
    {
        icon: BsDatabaseCheck,
        title: 'Database Connection',
        desc: 'Link your dematerialized documents to databases for easy access and reference, ensuring data integrity.'
    },
    {
        icon: LiaFileInvoiceDollarSolid,
        title: 'Document Management',
        desc: 'Our application simplifies the process of digitizing your paper documents and seamlessly integrating them into your Document Management System'
    },

    {
        icon: SiWebstorm,
        title: 'Web Services Integration',
        desc: 'Seamlessly connect to other applications and collect data with our web services feature, enabling efficient data sharing and synchronization.'
    },
    {
        icon: LuSettings,
        title: 'Reliability',
        desc: 'Benefit from high availability and data redundancy, reducing the risk of data loss and ensuring your documents are always accessible.'
    },

    {
        icon: GrDocumentUser,
        title: 'Indexing',
        desc: 'Assigning metadata to documents to make them searchable and sortable. This metadata may include information like document title, author, and keywords.'
    },

    {
        icon: MdOutlineDocumentScanner,
        title: 'Document Capture',
        desc: 'The process of capturing and digitizing paper documents or creating digital documents from electronic sources.'
    },
    {
        icon: MdUpdate,
        title: 'Automatic Updates:',
        desc: 'Enjoy automatic updates and improvements without the hassle of manual software maintenance.'
    },
    {
        icon: HiOutlineDocumentMagnifyingGlass,
        title: 'Data Extraction',
        desc: 'Extract valuable data from your scanned documents effortlessly, increasing productivity and accuracy.'
    },
    {
        icon: GoVersions,
        title: 'Version Control',
        desc: 'Managing different versions of documents to ensure that the most current and accurate version is used.'
    },

]

export const serverData = [
    {
        icon:LiaSwatchbookSolid,
        title:'Enhance Security',
        desc:'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon:BiTachometer,
        title:'High Performance',
        desc:'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon:FiUserCheck,
        title:'Unbeatable Support',
        desc:'There are many variations of passages of Lorem Ipsum available'
    },
]
export const accordionData = [
    {
        id:1,
        title:'How does it work ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:2,
        title:'Do I need a designer to use Hoxia ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:3,
        title:'What do I need to do to start selling ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:4,
        title:'What happens when I receive an order ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:5,
        title:'How does it work ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:6,
        title:'Do I need a designer to use Hoxia ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:7,
        title:'What do I need to do to start selling ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:8,
        title:'What happens when I receive an order ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
]
export const clientData2 = [
    {
       image:client1,
       name:'Calvin Carlo',
       date:'13th March 2023 at 1:00 pm',
       desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour" 
    },
    {
        image:client2,
        name:'Steven Townsend',
        date:'6th May 2023 at 10:00 am',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour" 
     },
     {
        image:client3,
        name:'Tiffany Betancourt',
        date:'19th June 2023 at 9:00 am',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour" 
     },
     {
        image:client4,
        name:'Jacqueline Burns',
        date:'20th June 2023 at 2:30 pm',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour" 
     },
]
export const feturesData = [
    {
        icon:AiOutlineQuestionCircle,
        title:'FAQs',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:LiaFileInvoiceDollarSolid,
        title:'Guides / Support',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:LuSettings,
        title:'Support Request',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]
export const FeatureData2 = [
    {
        icon: SiSecurityscorecard,
        title: 'Security',
        desc: 'Your Shield in the Digital World: Delivering Unparalleled Security.'
    },
    {
        icon: TbCloudHeart,
        title: 'Cloud',
        desc: 'Unleash the Potential of the Cloud: Where Innovation Meets Infinite Possibilities.'
    },
    {
        icon: IoIosAlbums,
        title: 'DMS',
        desc: 'Document management systems: storing, retrieving, and managing documents and information'
    },
    {
        icon: LuUsers,
        title: 'User friendly',
        desc: 'Effortlessly transform your paper processes into digital efficiency with our intuitive dematerialization platform. Digitize, store, and manage documents at a click!'
    },
    {
        icon: BiTachometer,
        title: 'IA / OCR',
        desc: 'Unlock the Power of AI-Driven OCR Technology for Effortless Document Transformation.'
    },
    {
        icon: BiDisc,
        title: 'Scalability',
        desc: 'Our dematerialization solution is designed to meet the needs of businesses of all sizes. We offer plans and features tailored to your specific needs.'
    },

]

export const priceData=[
    {
        titit:'Starter',
        price:'4.99',
        features:["We offers a free month of service for new customers.","10GB Ram","100GB Bandwith","100GB SSD Storage","3 Domain Hosted Support","50 Email Account","5 Database","Unlimited Traffic"]
    },
    {
        titit:'Business',
        price:'14.99',
        features:[" We offers a free 7 days of service for new customers.","30GB Ram","500GB Bandwith","500GB SSD Storage","10 Domain Hosted Support","100 Email Account","10 Database","Unlimited Traffic"]
    },
    {
        titit:'Enterprise',
        price:'49.99',
        features:["We offers a free 14 days of service for new customers.","100GB Ram","1000GB Bandwith","1000GB SSD Storage","Unlimited Domain Hosted Support","Unlimited Email Account","Unlimited Database","Unlimited Traffic"]
    },

]

export const  serverPlanOne = [
    {
        id:1,
        plans:'Basic',
        processor:'Intel E3',
        ram:'4 GB DDR3',
        disk:'1000 GB HDD',
        bandwidth:'5 TB',
        price:'$ 5.99/month'
    },
    {
        id:2,
        plans:'Standard',
        processor:'Intel E3',
        ram:'4 GB DDR3',
        disk:'1000 GB HDD',
        bandwidth:'5 TB',
        price:'$ 5.99/month'
    },
    {
        id:3,
        plans:'Elite',
        processor:'Intel E3',
        ram:'8 GB DDR3',
        disk:'1000 GB HDD',
        bandwidth:'5 TB',
        price:'$ 5.99/month'
    },
    {
        id:4,
        plans:'Professional',
        processor:'Intel E3',
        ram:'16 GB DDR3',
        disk:'1000 GB HDD',
        bandwidth:'10 TB',
        price:'$ 5.99/month'
    },
    {
        id:5,
        plans:'Advanced',
        processor:'Intel Xeon D',
        ram:'16 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'15 TB',
        price:'$ 5.99/month'
    },
    {
        id:6,
        plans:'Basic',
        title:'ST',
        color:'text-sky-500',
        processor:'Intel E3',
        ram:'28 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'10 TB',
        price:'$ 5.99/month'
    },
    {
        id:7,
        plans:'Advanced',
        title:'ELITE',
        color:'text-green-600',
        processor:'Intel E3',
        ram:'28 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'10 TB',
        price:'$ 5.99/month'
    },
    {
        id:8,
        plans:'Advanced',
        title:'BUSINESS',
        color:'text-yellow-500',
        processor:'Intel E3',
        ram:'60 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'15 TB',
        price:'$ 5.99/month'
    },
    {
        id:9,
        plans:'Advanced',
        title:'PRO',
        color:'text-purple-600',
        processor:'Intel E3',
        ram:'60 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'15 TB',
        price:'$ 5.99/month'
    }
]

export const clientLogo = [ClientLogo1,ClientLogo2,ClientLogo3,ClientLogo4,ClientLogo5,ClientLogo6,ClientLogo7,ClientLogo8,ClientLogo9,ClientLogo10,ClientLogo11,ClientLogo12]

export const orderData = [
    {
        orderNo:'7107',
        date:'13th March 2023',
        status:'Delivered',
        item:'for 2items',
        price:'$ 320'
    },
    {
        orderNo:'8007',
        date:'6th May 2023',
        status:'Processing',
        item:'for 1items',
        price:'	$ 800'
    },
    {
        orderNo:'8008',
        date:'19th June 2023',
        status:'Canceled',
        item:'for 1items',
        price:'$ 800'
    },
]