import React from "react";
import { Link } from "react-router-dom";

import mapImage from '../assets/images/map2.png'
import tunis from '../assets/images/flags/Tunisia.png'
import russia from '../assets/images/flags/russia.png'
import swiss from '../assets/images/flags/Switz.png'
import usa from '../assets/images/flags/usa.png'

export default function WorldMap(){
    return(
        <div className="container relative md:mt-24 mt-16">
            <div className="relative grid grid-cols-1 pb-8 text-center z-1">
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">Transformation Around The World</h3>

                <p className="text-slate-400 max-w-xl mx-auto">Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.</p>
            </div>

            <div className="grid grid-cols-1 pt-8">
                <div className="relative">
                    <img src={mapImage}className="mx-auto" alt=""/>

                    <Link to="" className="absolute top-[15%] start-[20%]"><img src={usa} className="md:h-8 h-4 md:w-8 w-4 rounded-full shadow dark:shadow-gray-800" alt="" title="Germany"/></Link>
                     <Link to="" className="absolute top-[25%] start-[50%]"><img src={swiss} className="md:h-8 h-4 md:w-8 w-4 rounded-full shadow dark:shadow-gray-800" alt="" title="Russia"/></Link>
                    <Link to="" className="absolute top-[25%] start-[70%]"><img src={russia} className="md:h-8 h-4 md:w-8 w-4 rounded-full shadow dark:shadow-gray-800" alt="" title="Spain"/></Link>
                    <Link to="" className="absolute top-[60%] start-[50%]"><img src={tunis} className="md:h-8 h-4 md:w-8 w-4 rounded-full shadow dark:shadow-gray-800" alt="" title="USA"/></Link>
                </div>
            </div>
        </div>
    )
}