import React from "react";
import {Link} from "react-router-dom";

import serverImg from '../assets/images/3.png'

import Navbar from "../components/navbar";
import WorldMap from "../components/worldMap";

import Footer from "../components/footer";
import Switcher from "../components/switcher";

import {serviceData, teamData} from "../data/data";
import {
    AiOutlineCheckCircle,
    MdMailOutline,
    FiFacebook,
    FiInstagram,
    FiLinkedin,
    AiOutlineQuestionCircle
} from '../assets/icons/vander'

export default function AboutUs() {

    return (
        <>
            <Navbar navClass="defaultscroll is-sticky" ulClass='navigation-menu justify-end nav-light' navLight={true}/>

            <section
                className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg6.jpg')] bg-center bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-sky-900/80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">About
                            Us</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                            <Link to="/index">Demat-Vision</Link></li>
                        <li className="inline-block text-[15px] text-white ltr:rotate-0 rtl:rotate-180"><i
                            className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block text-[15px] font-medium duration-500 ease-in-out text-white">About
                            Us
                        </li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div
                    className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5 md:col-span-6">
                            <img src={serverImg} alt=""/>
                        </div>

                        <div className="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                            <div className="lg:ms-5">
                                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">Welcome
                                    to Demat-Vision</h3>

                                <p className="text-slate-400 max-w-xl">Our aim is to help you going paperless with our
                                    cutting-edge dematerialization software! We've pioneered a groundbreaking
                                    dematerialization software that empowers organizations to seamlessly transition from
                                    the age of paper documents to the era of digital data. </p>
                                <p className="text-slate-400 max-w-xl mt-3">Say goodbye to cumbersome
                                    file cabinets, lost paperwork, and endless stacks of documents. With our software,
                                    your documents are securely stored, easily accessible, and fully searchable, all in
                                    the digital realm.</p>

                                <ul className="list-none text-slate-400 mt-4">
                                    <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                        className="text-sky-500 text-xl me-2"/>Innovative Dematerialization Software
                                    </li>
                                    <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                        className="text-sky-500 text-xl me-2"/>Expert Document Management Solutions
                                        Agency
                                    </li>
                                    <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                        className="text-sky-500 text-xl me-2"/>Custom Brand Integration
                                    </li>
                                </ul>

                                <div className="mt-6">
                                    <Link to="/contact"
                                          className="inline-flex items-center px-8 py-2.5 text-[16px] font-medium tracking-wide bg-sky-500 hover:bg-sky-600 border border-sky-500 hover:border-sky-600 text-white focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-md text-center align-middle transition-all duration-500"><MdMailOutline
                                        className="me-1"/> Contact us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <WorldMap/>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">Our Founders</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Founded by Nadhir Cheniti and Ryan Zaiem, a dynamic duo driven by a shared passion for innovation and a joint commitment to revolutionizing document management.</p>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-4 mt-8 gap-4">
                        {teamData.map((item, index) => {
                            return (
                                <div className="group text-center" key={index}>
                                    <div
                                        className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                                        <img src={item.image} className="" alt=""/>

                                        <ul className="list-none absolute top-1/2 -translate-y-1/2 -end-20 group-hover:end-5 transition-all duration-500 ease-in-out">

                                            <li className="mt-1"><Link to={item.linkedin}
                                                                       className="h-8 w-8 inline-flex items-center text-center justify-center text-base bg-sky-500 hover:bg-sky-600 border border-sky-500 hover:border-sky-600 text-white focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-full align-middle transition-all duration-500"><FiLinkedin
                                                className="h-4 w-4"/></Link></li>
                                        </ul>
                                    </div>

                                    <div className="content mt-3">
                                        <Link to=""
                                              className="text-lg hover:text-sky-500 transition-all duration-500 ease-in-out h5 font-medium">{item.name}</Link>
                                        <p className="text-slate-400">{item.title}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="container md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">Questions
                            & Answers</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Unlock Solutions: Explore, Collaborate, and Transform Your Ideas with Our AI-Powered Document Platform.</p>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        <div className="flex">
                            <AiOutlineQuestionCircle className="h-8 text-sky-500 me-3 w-6"/>
                            <div className="flex-1">
                                <h5 className="mb-2 text-lg font-medium">How our <span
                                    className="text-sky-500">Demat-Vision</span> work ?</h5>
                                <p className="text-slate-400">Start by creating dematerialization projects tailored to your specific needs. All you need to do is chatting with our IA, to guide throw the process creation step by step until you achieve your goal!
                                    Scan your physical documents using any compatible scanner. Our software automatically recognizes and classifies these documents based on the created project, making organization a breeze.</p>
                            </div>
                        </div>

                        <div className="flex">
                            <AiOutlineQuestionCircle className="h-8 text-sky-500 me-3 w-6 "/>
                            <div className="flex-1">
                                <h5 className="mb-2 text-lg font-medium"> What is the main process open account ?</h5>
                                <p className="text-slate-400">To create an account on our platform, users begin by registering with their basic information. Following registration,
                                    they select a subscription plan tailored to their needs and make a secure payment. Once the payment is confirmed, users receive an email
                                    confirmation and gain access to their active account. They can then log in and immediately start utilizing the platform's features and services.</p>
                            </div>
                        </div>

                        <div className="flex">
                            <AiOutlineQuestionCircle className="h-8 text-sky-500 me-3 w-6"/>
                            <div className="flex-1">
                                <h5 className="mb-2 text-lg font-medium"> Do you have an existing project management process ?</h5>
                                <p className="text-slate-400">Yes, our platform seamlessly integrates with your existing project workflows.
                                    You can easily incorporate client documents into your projects, collaborate with your team, and efficiently
                                    manage all project-related files using our robust document management system. Our goal is to enhance your project
                                    management experience by providing the tools you need to work smarter and more effectively with client documents.
                                    Explore how our platform can streamline your projects today!"</p>
                            </div>
                        </div>

                        <div className="flex">
                            <AiOutlineQuestionCircle className="h-8 text-sky-500 me-3 w-6"/>
                            <div className="flex-1">
                                <h5 className="mb-2 text-lg font-medium"> Is <span
                                    className="text-sky-500">Demat-Vision</span> safer to use with my account ?</h5>
                                <p className="text-slate-400">Absolutely, Demat-Vision is a secure and trustworthy platform. We have taken comprehensive measures to ensure the safety of your data, including utilizing a secure cloud infrastructure with industry-standard encryption protocols. Our platform is also certified to meet rigorous security standards. However, your own diligence in safeguarding your account and data is equally important. We encourage you to explore our platform with confidence and take advantage of
                                    the robust security measures we've put in place to protect your information."</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <Switcher/>
        </>
    )
}