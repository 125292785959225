import React, {useState} from "react";
import {Link} from "react-router-dom";
import DOMPurify from 'dompurify';
import contactImg from '../assets/images/contact.svg'
import {isValidEmail} from '../utils/utils';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";


import {BsTelephone, LuMail, FiMapPin} from '../assets/icons/vander'

export default function Contact() {
    const [notification, setNotification] = useState(null);

    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        comments: '',
    });
    const handleChange = (e) => {
        const {name, value} = e.target;

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        console.log("handleSubmit called");
        event.preventDefault();

        // Sanitize each form field
        const sanitizedName = DOMPurify.sanitize(formData.name.trim());
        const sanitizedEmail = DOMPurify.sanitize(formData.email.trim());
        const sanitizedSubject = DOMPurify.sanitize(formData.subject.trim());
        const sanitizedComments = DOMPurify.sanitize(formData.comments.trim());

        // Validate email
        const isEmailValid = isValidEmail(sanitizedEmail);
        const errors = {};

        if (!sanitizedName) {
            errors.name = "Please enter your name";
        }

        if (!sanitizedEmail) {
            errors.email = "Please enter your email address";
        } else if (!isEmailValid) {
            errors.email = "Invalid email address";
        }


        if (!sanitizedSubject) {
            errors.subject = "Please enter your phone number";
        }

        if (!sanitizedComments) {
            errors.comments = "Please enter your message";
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        // Process the form data here (e.g., send it to a server)
        console.log({
            name: sanitizedName,
            email: sanitizedEmail,
            subject: sanitizedSubject,
            comments: sanitizedComments,
        });

        // Reset the form after submission
        setFormData({
            name: '',
            email: '',
            subject: '',
            comments: '',
        });
        const sanitizedData = {
            name: sanitizedName,
            email: sanitizedEmail,
            subject: sanitizedSubject,
            comments: sanitizedComments,
        };


        try {
            console.log("Sending request to server...");
            const response = await fetch('http://localhost:3001/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sanitizedData),
            });
            console.log("Response received:", response);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            if (response.ok) {
                // Email sent successfully
                // Reset the form
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    comments: '',
                });
                setNotification({type: 'success', message: 'Message sent successfully!'});
                console.log('Form errors after reset:', formErrors);
            } else {
                // Error sending email
                setNotification({type: 'error', message: 'Error sending message.'});

                console.error('Error sending email');
            }

        } catch (error) {
            console.error('Error:', error);
            setNotification({type: 'error', message: 'Error sending message.'});

        }

    };

    return (
        <>
            <Navbar navClass="defaultscroll is-sticky" ulClass='navigation-menu justify-end nav-light' navLight={true}/>

            <section
                className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg6.jpg')] bg-center bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-sky-900/80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Contact
                            Us</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                            <Link to="/index">Demat-Vision</Link></li>
                        <li className="inline-block text-[15px] ltr:rotate-0 rtl:rotate-180"><i
                            className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block text-[15px] font-medium duration-500 ease-in-out text-sky-500">Contact
                            Us
                        </li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div
                    className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contactImg} alt=""/>
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium">Get in touch !</h3>

                                    <form onSubmit={handleSubmit}>
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                         {notification && (
                                                <div className={`notification ${notification.type}`}>
                                                    {notification.message}
                                                </div>
                                            )}
                                        <div className="grid lg:grid-cols-12 lg:gap-6">


                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="font-medium">Your Name:</label>
                                                <input name="name" id="name" value={formData.name}
                                                       onChange={handleChange} type="text"
                                                       className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-lg outline-none border border-gray-200 focus:border-sky-500 dark:border-gray-800 dark:focus:border-sky-500 focus:ring-0 mt-2"
                                                       placeholder="Name :"/>
                                                {formErrors.name &&
                                                    <p className="error-message text-xs mt-1">{formErrors.name}</p>}
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="font-medium">Your Email:</label>
                                                <input name="email" id="email" type="email" value={formData.email}
                                                       onChange={handleChange}
                                                       className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-lg outline-none border border-gray-200 focus:border-sky-500 dark:border-gray-800 dark:focus:border-sky-500 focus:ring-0 mt-2"
                                                       placeholder="Email :"/>
                                                {formErrors.email &&
                                                    <p className="error-message text-xs mt-1">{formErrors.email}</p>}
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="subject" className="font-medium">Your Question:</label>
                                                <input name="subject" id="subject" value={formData.subject}
                                                       onChange={handleChange}
                                                       className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-lg outline-none border border-gray-200 focus:border-sky-500 dark:border-gray-800 dark:focus:border-sky-500 focus:ring-0 mt-2"
                                                       placeholder="Subject :"/>
                                                {formErrors.subject &&
                                                    <p className="error-message text-xs mt-1">{formErrors.subject}</p>}
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="comments" className="font-medium">Your Comment:</label>
                                                <textarea name="comments" id="comments" value={formData.comments}
                                                          onChange={handleChange}
                                                          className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-lg outline-none border border-gray-200 focus:border-sky-500 dark:border-gray-800 dark:focus:border-sky-500 focus:ring-0 mt-2"
                                                          placeholder="Message :"></textarea>
                                                {formErrors.comments &&
                                                    <p className="error-message text-xs mt-1">{formErrors.comments}</p>}
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send"
                                                className="inline-block px-8 py-2.5 text-[16px] font-medium tracking-wide bg-sky-500 hover:bg-sky-600 border border-sky-500 hover:border-sky-600 text-white focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-md text-center align-middle transition-all duration-500">Send
                                            Message
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div
                                    className="w-14 h-14 bg-sky-500/5 text-sky-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <BsTelephone/>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-medium">Phone</h5>
                                <p className="text-slate-400 mt-3">Empowering Connections: Uniting Innovation and
                                    Impact.</p>

                                <div className="mt-4">
                                    <Link to="tel:+152534-468-854"
                                          className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out">+41
                                        076 502 99 24 </Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div
                                    className="w-14 h-14 bg-sky-500/5 text-sky-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <LuMail/>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-medium">Email</h5>
                                <p className="text-slate-400 mt-3">Join the Digital Revolution: Innovate, Impact, and
                                    Connect. Reach out to us at</p>

                                <div className="mt-4">
                                    <Link to="mailto:info@demat-vision.ch"
                                          className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out">info@demat-vision.ch</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div
                                    className="w-14 h-14 bg-sky-500/5 text-sky-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <FiMapPin/>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-medium">Location</h5>
                                <p className="text-slate-400 mt-3">Ch des Jonchets, 1030 Bussigny <br/> Switzerland</p>

                                <div className="mt-4">
                                    <Link
                                        to="https://www.google.com/maps/place/1030+Bussigny/data=!4m2!3m1!1s0x478c33c89d1b1a01:0x506a925520498cc3?sa=X&ved=2ahUKEwib4sSjm7iDAxWM_bsIHStlB7MQ8gF6BAgVEAA"
                                        data-type="iframe"
                                        className="read-more lightbox relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-sky-500 hover:text-sky-500 after:bg-sky-500 duration-500 ease-in-out">View
                                        on Google map</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <Switcher/>
        </>
    )
}