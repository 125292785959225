import React, {useEffect, useState} from "react";
import {Link, useLocation} from 'react-router-dom'

import logoDark from '../assets/images/Logodark.svg'
import logoLight from '../assets/images/Logowhite.svg'
import logoWhite from '../assets/images/Logowhite.svg'

export default function Navbar({navClass, ulClass, navLight}) {
    let [isOpen, setMenu] = useState(true);
    let [scroll, setScroll] = useState(false);



    const [manu, setManu] = useState('');
    const location = useLocation();

    useEffect(() => {
        var current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)
    }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])

    useEffect(() => {
        function scrollHandler() {
            setScroll(window.scrollY > 50)
        }

        window.addEventListener('scroll', scrollHandler);


        return () => {
            window.removeEventListener('scroll', scrollHandler);
       };

    }, []);

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }


    return (
        <nav id="topnav" className={`${scroll ? 'nav-sticky' : ''} ${navClass}`}>
            <div className="container relative">
                <Link className="logo" to="/index">
                    {navLight ?
                        <span className="inline-block dark:hidden">
                        <img src={logoDark} className="h-7 l-dark" alt=""/>
                        <img src={logoLight} className="h-7 l-light" alt=""/>
                    </span> :
                        <span className="inline-block">
                        <img src={logoDark} className="h-7 inline-block" alt=""/>
                    </span>
                    }
                    <img src={logoWhite} className="h-7 hidden dark:inline-block" alt=""/>
                </Link>
                <div className="menu-extras">
                    <div className="menu-item">
                        <Link className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`}>
                    <ul className={`${navClass ? `${ulClass}` : ' navigation-menu justify-end '}  `}>
                        <li className={manu === "index" ? "active" : ""}><Link to="/index"
                                                                               className="sub-menu-item">Home</Link>
                        </li>

                        <li className={manu === "aboutus" ? "active" : ""}><Link to="/aboutus"
                                                                                 className="sub-menu-item">About
                            Us</Link></li>

                        <li><Link to="/contact" className="sub-menu-item">Contact</Link></li>
                        <li><Link to="/login" className="sub-menu-item">Login</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}