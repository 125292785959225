import React from "react";

export default function Footer(){
    return(
        <footer className="footer bg-slate-900 dark:bg-slate-950 relative text-gray-200">

            <div className="py-[30px] px-0 border-t border-slate-800">
                <div className="container relative text-center">
                    <div className="grid md:grid-cols-1">
                        <p className="mb-0">© {new Date().getFullYear()} Demat-Vision. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
    
    
}