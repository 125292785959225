import React, {useState} from "react";
import {Link} from "react-router-dom";
import Navbar from "../../../../../DematVision/Demat_ReactJs_v1.0.0/Demat_ReactJs/src/components/navbar";
import ServerOne from "../../../../../DematVision/Demat_ReactJs_v1.0.0/Demat_ReactJs/src/components/serverOne";
import PricingOne from "../../../../../DematVision/Demat_ReactJs_v1.0.0/Demat_ReactJs/src/components/pricingOne";
import Footer from "../../../../../DematVision/Demat_ReactJs_v1.0.0/Demat_ReactJs/src/components/footer";
import Switcher from "../../../../../DematVision/Demat_ReactJs_v1.0.0/Demat_ReactJs/src/components/switcher";
import "react-modal-video/css/modal-video.css"
import {FeatureData2} from "../data/data";
import {FiHexagon} from 'react-icons/fi'
import HostingServices from "../../../../../DematVision/Demat_ReactJs_v1.0.0/Demat_ReactJs/src/components/hostingServices";
import bannerone from '../assets/images/4.png'

export default function IndexThree() {
    let [isOpen, setOpen] = useState(false);
    return (
        <>

            <Navbar navClass="defaultscroll is-sticky tagline-height" ulClass='navigation-menu justify-end nav-light'
                    navLight={true}/>

            <section className="relative lg:py-44 py-36 bg-gradient-to-br from-sky-600 to-yellow-500">
                <div
                    className="absolute inset-0 bg-[url('../../assets/images/bg/shape-1.png')] bg-center bg-cover"></div>
                <div className="container relative">
                    <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                        <div className="md:col-span-7">
                            <div className="me-6">
                                <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-white">Digitalizing
                                    the future <br/> Simplifying the present</h4>
                                <p className="text-white/70 max-w-xl">Demat-Vision is a technology company
                                    offering an intuitive, secure, and
                                    eco-friendly dematerialization platform. We help businesses transform their
                                    paper processes into an efficient and sustainable digital environment. We
                                    innovate for a more connected and environmentally respectful future.</p>

                                <div className="mt-6">
                                    <Link to="/aboutus"
                                          className="inline-block px-8 py-2.5 text-[16px] font-medium tracking-wide bg-sky-500 hover:bg-sky-600 border border-sky-500 hover:border-sky-600 text-white focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-md text-center align-middle transition-all duration-500">Learn
                                        More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="relative md:col-span-5">
                            <img src={bannerone} className="mover" alt=""/>
                            <div
                                className="overflow-hidden after:content-[''] after:absolute after:h-12 after:w-12 after:bg-yellow-500/20 after:top-0 after:start-0 after:z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>
                        </div>
                    </div>

                    <div className="relative animate-[spin_60s_linear_infinite] z-1">
                        <span
                            className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-yellow-500/20 relative after:z-10"></span>
                        <span
                            className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-yellow-500/20 relative after:z-10"></span>
                    </div>
                </div>
            </section>

            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:pb-24 pb-16 -mt-[86px] z-1">
                <div className="container">
                    <div
                        className="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 justify-center gap-[30px]">
                        {FeatureData2.map((item, index) => {
                            let Icon = item.icon
                            return (
                                <div className="relative overflow-hidden text-transparent -m-3" key={index}>
                                    <FiHexagon
                                        className="sm:h-48 h-40 sm:w-48 w-40 fill-gray-50 dark:fill-slate-800 mx-auto"/>
                                    <div
                                        className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto transition-all duration-500 ease-in-out text-4xl grid align-middle justify-center items-center">
                                        <Icon className="text-4xl text-sky-500 mx-auto"/>
                                        <Link to=""
                                              className="sm:text-lg text-base font-medium mt-1 h5 text-slate-900 hover:text-sky-500 dark:text-white dark:hover:text-sky-500 transition-all duration-500 ease-in-out">{item.title}</Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <HostingServices title="Features"/>
                <ServerOne/>
                <PricingOne/>

            </section>

            <Footer/>
            <Switcher/>
        </>
    )
}