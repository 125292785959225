import React from "react";
import { Link } from "react-router-dom";

import {AiOutlineCheckCircle} from '../assets/icons/vander'

export default function PricingOne(){
    return(
            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-medium">Explore Our Pricing</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">Unlock the Power of Efficiency: Discover Our Pricing for Cloud-Based Document.</p>
                </div>

                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    <div className="group p-6 relative overflow-hidden shadow-lg dark:shadow-gray-800 rounded-md h-fit">
                        <h6 className="font-medium mb-5 text-xl">Base</h6>

                        <div className="flex mb-5">
                            <span className="text-lg font-medium">CHF</span>
                            <span className="price text-5xl h6 font-semibold mb-0">99</span>
                            <span className="text-lg font-medium self-end mb-1">/mo</span>
                        </div>

                        <ul className="list-none text-slate-400">
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/><span
                                className="mt-0.5">Basic Storage (1TB).</span></li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/><span
                                className="mt-0.5">Up to 1000 pages/month.</span></li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">2 included users, maximum</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/><span className="mt-0.5">Email support</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/><span className="mt-0.5">Online training</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/><span className="mt-0.5">Ideal for small businesses</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">Non-expandable storage</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">Concurrent Projects Up to 5</span>
                            </li>

                        </ul>
                        <Link to="/comingsoon"
                              className="inline-block px-8 py-2.5 text-[16px] font-medium tracking-wide bg-transparent hover:bg-sky-500 border border-gray-100 dark:border-gray-800 hover:border-sky-500 dark:hover:border-sky-500 text-slate-900 dark:text-white hover:text-white focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-md text-center align-middle transition-all duration-500 w-full mt-5">Subscribe Now</Link>
                    </div>

                    <div className="group p-6 relative overflow-hidden shadow-lg dark:shadow-gray-800 rounded-md h-fit">
                        <h6 className="font-medium mb-5 text-xl">Pro</h6>

                        <div className="flex mb-5">
                            <span className="text-lg font-medium">CHF</span>
                            <span className="price text-5xl h6 font-semibold mb-0">199</span>
                            <span className="text-lg font-medium self-end mb-1">/mo</span>
                        </div>

                        <ul className="list-none text-slate-400">
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">2TB Storage</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">Up to 5000 pages/month</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">6 included users, maximum</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/><span className="mt-0.5">Telephone support</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/><span className="mt-0.5">Perfect for growing SMEs</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/><span className="mt-0.5">Online training</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">Expandable storage up to 18TB</span>
                            </li>
                            <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">Concurrent Projects Up to 10</span>
                            </li>
                        </ul>
                        <Link to="/comingsoon"
                              className="inline-block px-8 py-2.5 text-[16px] font-medium tracking-wide bg-transparent hover:bg-sky-500 border border-gray-100 dark:border-gray-800 hover:border-sky-500 dark:hover:border-sky-500 text-slate-900 dark:text-white hover:text-white focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-md text-center align-middle transition-all duration-500 w-full mt-5">Subscribe
                            Now</Link>
                    </div>

                    <div className="group relative overflow-hidden shadow-lg dark:shadow-gray-800 rounded-md h-fit">
                        <div
                            className="bg-gradient-to-tr from-sky-500 to-blue-700 text-white py-2 px-6 h6 text-lg font-medium">Popular
                        </div>
                        <div className="p-6">
                            <h6 className="font-medium mb-5 text-xl">Entreprise</h6>

                            <div className="flex mb-5">
                                <span className="text-lg font-medium">CHF</span>
                                <span className="price text-5xl h6 font-semibold mb-0">399</span>
                                <span className="text-lg font-medium self-end mb-1">/mo</span>
                            </div>

                            <ul className="list-none text-slate-400">
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/><span
                                    className="mt-0.5">6TB Storage</span></li>
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/> <span
                                    className="mt-0.5">Unlimited pages/month.</span>
                                </li>
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">7 included users</span>
                                </li>
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/><span className="mt-0.5">CHF 20 per additional user</span>
                                </li>
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/><span className="mt-0.5">Premium 24/7 support</span>
                                </li>
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/><span className="mt-0.5">Suitable for large enterprises</span>
                                </li>
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">Online training</span>
                                </li>
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">Customized training</span>
                                </li>
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">Expandable storage up to 106TB</span>
                                </li>
                                <li className="mb-1 flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-0.5">Concurrent Projects Up to 20</span>
                                </li>
                            </ul>
                            <Link to="/comingsoon"
                                  className="inline-block px-8 py-2.5 text-[16px] font-medium tracking-wide bg-sky-500 hover:bg-sky-600 border border-sky-500 hover:border-sky-600 text-white focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-md text-center align-middle transition-all duration-500 w-full mt-5">Subscribe Now</Link>

                            <p className="text-sm text-slate-400 mt-1.5"><span className="text-red-600">*</span>T&C
                                Apply</p>
                        </div>
                    </div>

                    <div
                        className="group p-[1px] relative overflow-hidden shadow-lg dark:shadow-gray-800 rounded-md bg-gradient-to-tr from-sky-500 to-blue-700 h-fit">
                        <div className="p-6 bg-white dark:bg-slate-900 rounded-md">
                            <h6 className="font-medium mb-5 text-xl">Custom</h6>

                            <p className="text-slate-400 mb-5">Pricing plan will be as per client or company
                                requirements</p>

                            <ul className="list-none">
                                <li className="mb-1 font-medium flex items-center"><AiOutlineCheckCircle
                                    className="text-sky-500 text-[20px] align-middle me-2"/> <span className="mt-1">Custom Pricing</span>
                                </li>
                            </ul>
                            <Link to="/contact"
                                  className="inline-block px-8 py-2.5 text-[16px] font-medium tracking-wide bg-transparent hover:bg-sky-500 border border-gray-100 dark:border-gray-800 hover:border-sky-500 dark:hover:border-sky-500 text-slate-900 dark:text-white hover:text-white focus:ring-[3px] focus:ring-sky-500 focus:ring-opacity-25 focus:outline-none rounded-md text-center align-middle transition-all duration-500 w-full mt-5">Talk
                                to us</Link>
                        </div>
                    </div>
                </div>
            </div>
    )
}